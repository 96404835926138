import styled from "styled-components"
import { device } from "breakpoints"
import { MainColumn } from "components/MainColumn"

export const TeamPageContainer = styled(MainColumn)`
  overflow: hidden;
  margin-bottom: 40px;
`

export const Member = styled.div`
  display: flex;
  flex-direction: column;

  margin-bottom: 20px;
  @media ${device.desktop} {
    flex-direction: row;
    margin-bottom: 60px;
  }
`

export const MemberImgWrapper = styled.div`
  border-radius: 50%;
  height: 90px;
  width: 90px;
  margin-bottom: 24px;
  position: relative;
  @media ${device.desktop} {
    width: calc(33.33333% - 30px);
    padding-top: calc(33.333333% - 30px);
    flex-grow: 1;
    margin-right: 30px;
    margin-bottom: 0px;
  }

  .gatsby-image-wrapper {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

export const Text = styled.div`
  @media ${device.desktop} {
    width: 66.6666666%;
    flex-grow: 0;
    padding-left: 30px;
    padding-top: 110px;
  }
  p {
    font-size: 16px;
    margin-bottom: 20px;
    color: #5a5a5a;
    font-weight: 300;
    letter-spacing: 0.5px;

    @media ${device.desktop} {
      font-size: 18px;
      margin-bottom: 25px;
    }
  }

`

export const Title = styled.div`
  position: absolute;
  top: 50%;
  right: -14px;
  transform: translate3d(100%, -50%, 0);
  width: 48vw;

  @media ${device.desktop} {
    top: 13px;
    right: -60px;
    transform: translate3d(100%, 0%, 0);
  }
  h3 {
    font-size: 24px;
    margin-bottom: 5px;
    color: ${props => props.theme.blue};

    @media ${device.desktop} {
      font-size: 36px;
      margin-bottom: 8px;
    }
  }

  span {
    font-size: 16px;
    font-weight: 500;
    color: #5a5a5a;
  }
`
