import React from "react"
import {
  TeamPageContainer,
  Member,
  Title,
  Text,
  MemberImgWrapper,
} from "./style"
import { useStaticQuery, graphql } from "gatsby"
import AvenueImg from "components/AvenueImg"

const TeamPage = () => {
  const data = useStaticQuery(
    graphql`
      query {
        wpgraphql {
          peoples(where: { orderby: { field: MENU_ORDER, order: ASC } }) {
            nodes {
              title
              memberDetails {
                position
                description
                photo {
                  sourceUrl
                  srcSet
                  mediaDetails {
                    width
                    height
                  }
                }
              }
            }
          }
        }
      }
    `
  )

  const members = data.wpgraphql.peoples.nodes
  return (
    <TeamPageContainer>
      {members.map((member, index) => {
        return (
          <Member key={index}>
            <MemberImgWrapper>
              <AvenueImg fluid={member.memberDetails.photo} />
              <Title>
                <h3>{member.title}</h3>
                <span>{member.memberDetails.position}</span>
              </Title>
            </MemberImgWrapper>
            <Text
              dangerouslySetInnerHTML={{
                __html: member.memberDetails.description,
              }}
            />
          </Member>
        )
      })}
    </TeamPageContainer>
  )
}

export default TeamPage
