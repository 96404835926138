import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { TeamPage } from "../components/TeamPage"

const OurTeam = ({ pageContext }) => {
  const { title } = pageContext
  return (
    <Layout
      title={title}
      excerpt="At Voice there is a ‘we minded’ approach to everything we do. We know our stuff and we enjoy doing what we do. We always remember that we’re working with you to build your brand, our approach is collaborative and inclusive. It’s our people that make Voice, Voice."
    >
      <SEO title={title} />
      <TeamPage />
    </Layout>
  )
}

export default OurTeam
